<template>
 <div class="row h-100  justify-content-center">
   <!--<Preloader :status="preloaderActive"></Preloader>-->
    <div class="col-lg-10 col-xl-10 justify-content-start">
      <div class="d-flex flex-column px-7 mt-10">
        <div class="row align-items-center">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column">
                <h2 class="card-label h1 font-weight-bolder">{{$t("GROUPLIST.COMMUNITY")}}</h2>
            </div>
          </div>
        </div>
         <!--::Search-->
        <div class="row w-100" >
          <div class="col-xl-12">
            <form method="get" class="quick-search-form">
              <div class="input-group bg-tranparent border-0">
                <div class="input-group-prepend bg-transparent">
                  <button
                    class="input-group-text line-height-0 py-0 bg-transparent border-0"
                    v-on:click="getSearchData($event)"
                  >
                    <img
                      src="/assets/media/bipart/ico-search.png"
                      alt=""
                      class="w-100"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control py-10 h4 bg-transparent border-0 text-dark-50"
                  placeholder="Search..."
                  v-model="keywords"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
                  >
                    {{ groupsCount }}
                    <span class="text-muted h6 ml-4">{{$t("GROUPLIST.GRUPPI")}}</span>
                  </span>
                </div>
                <!-- <div class="input-group-append">
                  <span
                    class="input-group-text h3 px-10 text-primary font-weight-bold bg-transparent border-0"
                  >
                    {{nr_users}} <span class="text-muted h6 ml-4">utenti</span>
                  </span>
                </div> -->
              </div>
            </form>
          </div>
        </div>

        <!--begin::Elenco Gruppi -->
        <div class="row mt-5"  v-if="groups.length > 0">
          <div
            id="my-cards"
            class="col-12 col-md-6 col-lg-4 col-xl-3"
            v-for="(item, index) in groups"
            :key="item.id"
          >
            <GroupCard :group="item" :index="index" ></GroupCard>
          </div>

        </div>

<!--        <div class="row mt-5" v-else>-->
<!--          <button type="button" class="btn btn-primary spinner spinner-white spinner-right">-->
<!--              Stiamo elaborando... 0 gruppi-->
<!--          </button>-->
<!--        </div>-->

        <!--::Pagination -->
        <Pagination
          v-if="pageCount > 1"
          :current-page="currentPage"
          :page-count="pageCount"
          :item-count="groupsCount"
          :per-page="$options.static.visibleItemsPerPageCount"
          :visiblePagesCount="Math.ceil(groupsCount/$options.static.visibleItemsPerPageCount) + 1"
          class="process-list-pagination gutter-b"
          @firstPage="pageChangeHandle('first')"
          @nextPage="pageChangeHandle('next')"
          @previousPage="pageChangeHandle('previous')"
          @lastPage="pageChangeHandle('last')"
          @loadPage="pageChangeHandle"
        ></Pagination>




      </div>

    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Pagination from "@/view/layout/common/Pagination";
import GroupCard from "./partials/GroupCard";
//import OrganizationMenu from "@/view/layout/common/OrganizationMenu.vue";
//import Preloader from "@/view/content/Preloader";

export default {
  name: "GroupList",
  components: {
    Pagination,
    GroupCard,
    //OrganizationMenu
    //Preloader
  },
  static: {
    visibleItemsPerPageCount: 12
  },
  data() {
    return {
      nr_users: 0,
      groups: [],
      groupsCount: null,
      currentPage: 1,
      pageCount: 0,
      keywords: "",
      items: [this.$t("GROUPLIST.UNO"), this.$t("GROUPLIST.DUE"), this.$t("GROUPLIST.TRE")],

      orderField: "",
      orderVersus: "",
      orders: [
        {
          id: 0,
          label: this.$t("GROUPLIST.DDC"),
          field: "createdDate"
        }
      ],
      preloaderActive: false

    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Elenco gruppi" }]);

    this.getData();
    this.getUsers();
  },
  created() {},
  methods: {
    getData() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        //this.preloaderActive = true;
        let qs =
          "size=" +
          this.$options.static.visibleItemsPerPageCount +
          "&page=" +
          this.currentPage;

        // if (this.filterScenarioId > 0) qs += "&scenarioId.equals=" + this.filterScenarioId;
        // if (this.filterChallengesId > 0) qs += "&challengeId.equals=" + this.filterChallengeId;
        //if (this.keywords) qs += "&title.contains=" + this.keywords;

        if (this.orderField) qs += "&sort=" + this.orderField + ','  + this.orderVersus;

        if (this.keywords) qs += "&name.contains=" + this.keywords;

        var endpoint = this.generateUrl("groups-page", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint + "?" + qs)
          .then(res => {
            // console.log(res);
            this.groups = (res.data.content) ? res.data.content.filter(x => x.publiclyVisible) : [];
            this.groupsCount = this.groups.length;
            this.pageCount = Math.ceil(
              res.data.meta.total /
                this.$options.static.visibleItemsPerPageCount
            );
            this.preloaderActive = false;
            this.$isLoading(false);
            // console.log(this.groups);
            // console.log(this.tot_groups);
          })
          .catch(() => {
            // .catch(({ response }) => {
            //   console.log(response);
            // context.commit(SET_ERROR, [response.data.error]);
            this.preloaderActive = false;
            this.$isLoading(false);
            reject(0);
          });
      });
    },
    getUsers() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl("users", true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint + "?persona.equals=true")
            .then(res => {
              this.nr_users = res.data.length;
              this.$isLoading(false)
            })
            .catch(() => {
              this.$isLoading(false);
              // .catch(({ response }) => {
              // context.commit(SET_ERROR, [response.data.error]);
              reject(0);
            });
      });
    },

    getSearchData(event) {
      event.preventDefault();
      this.getData();
    },
    async pageChangeHandle(value) {
      switch (value) {
        case "next":
          this.currentPage += 1;
          break;
        case "previous":
          this.currentPage -= 1;
          break;
        case "first":
          this.currentPage = 1;
          break;
        case "last":
          this.currentPage = this.pageCount;
          break;
        default:
          this.currentPage = value;
      }
      await this.getData();
    },

    orderBy(event, field = "id", versus = "desc") {
      event.preventDefault();
      this.orderField = field;
      this.orderVersus = versus;

      this.getData();
    },

  }
};
</script>

<style scoped></style>
