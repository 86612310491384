<template>

  <div class="card card-custom card-stretch gutter-b">
    <div class="card-body  text-center">

      <div
        class="d-none d-md-block py-1"
        style="max-height: 180px; min-height: 180px; overflow: hidden; position: relative;">
        <div class="" v-if="group.publiclyVisible != false">
          <router-link
            :to="{ name: 'groupDetail', params: { id: group.id, group_name: group.name } }">
            <img
              :src="group.logo ? group.logo.uri  : $imageOptions.defaultGroupUrl"
              class="align-self-center w-100"
              style="position: absolute; margin: auto; top: 0;left: 0;right: 0;"
              alt="Avatar"
            />
          </router-link>
        </div>
        <div class="" v-else>
          <a
            class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
            href="#"
            @click.prevent="alertInfo"
            id="pop-info">
            <img
              :src="(group.logo && group.logo.uri) ? group.logo.uri  : $imageOptions.defaultGroupUrl"
              class="align-self-center w-100"
              style="position: absolute; margin: auto; top: 0;left: 0;right: 0;"
              alt="Avatar"
            />
          </a>
        </div>
      </div>

      <div class="d-flex mr-2" v-if="group.publiclyVisible && group.publiclyVisible == false">
        <span
          class="label label-inline label-xl font-weight-boldest label-rounded py-5"
          v-bind:class="{ 'label-light-primary': group.publiclyVisible, 'label-light-danger' : !group.publiclyVisible }"
        >
          <!-- <i class="fas fa-lock-open text-primary" v-if="group.publiclyVisible != false"></i> -->
          <i class="fas fa-lock text-danger"></i>
        </span>
      </div>
      <div
        class="mt-3 text-dark font-weight-bolder text-hover-primary font-size-h5 text-left"
        v-if="group.publiclyVisible != false">

         <router-link
          :to="{ name: 'groupDetail', params: { id: group.id, group_name: group.name } }"
          v-slot="{ href, navigate }">
          <a :href="href" class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
          :class="{ '': group.enabled && index > 0 }"  @click="navigate">{{ group.name }}</a>
          </router-link>

<!--        <router-link-->
<!--          :to="{ name: 'groupDetail', params: { id: group.id, group_name: group.name } }">-->
<!--          <span-->
<!--            class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"-->
<!--            :class="{ '': group.enabled && index > 0 }"-->
<!--          >{{ group.name }}</span>-->
<!--        </router-link>-->

        <div class="text-muted font-weight-bold">{{ group.countUsers }} utenti</div>
      </div>
      <div
        class="mt-3 text-dark font-weight-bolder text-hover-primary font-size-h5 text-left"
        v-else>
         <a
            class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0"
            href="#"
            @click.prevent="alertInfo"
            id="pop-info">
            {{ group.name }}
          </a>
           <div class="text-muted font-weight-bold">{{ group.countUsers }} utenti</div>
      </div>
      <!-- Elenco users
      <p class="mb-7">{{ group.description }}</p>
      <div class="d-flex flex-row mb-7">
        <div v-if="group.users.length > 0" class="d-flex">
          <div class="d-flex align-items-center">
            <div
              v-for="user in iconsUsers"
              :key="user.id"
              class="d-flex align-items-center"
            >
              <div class="symbol symbol-circle mr-3">
                <img
                  :src="
                    user.avatar
                      ? user.avatar.uri
                      : '/assets/media/users/default.jpg'
                  "
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>


</template>

<script>

import Swal from "sweetalert2";

export default {
  name: "GroupCard",
  props: ["group", "index"],

  data() {
    return {
      //iconsUsers: this.group.users.reverse().slice(0, 3)
    };
  },
  filters: {
    fvisibility(value) {
      return value ? "Pubblico" : "Privato";
    }
  },
  mounted() {
    console.log('Gruppo:', this.group);
  },
  methods: {
    alertInfo(){
      Swal.fire({
        title: "<b>Il gruppo è privato</b>",
        html:
        '<h4>Ci spiace! Questo gruppo è visibile solo a chi ne fa parte.</h4> ',
        icon: "danger",
        confirmButtonClass: "btn btn-danger"
      });
    }
  },
};
</script>

<style scoped></style>
